.nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.nav a,
.nav button {
  background: #eee;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transform: 0.1s;
  cursor: pointer;
}

.nav a:hover,
.nav a:focus,
.nav button:hover,
.nav button:focus {
  background: white;
  box-shadow: 0 0 0 3px;
  border-color: #333;
  outline: none;
}

.nav a:global(.active) {
  background: white;
  box-shadow: 0 0 0 3px #fb1;
}

.nav a:global(.active) svg > * {
  fill: #fb1;
}

.mobileButton {
  background: #eee;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transform: 0.1s;
  cursor: pointer;
}

.mobileButton::after {
  content: '';
  display: block;
  height: 2px;
  width: 1.2rem;
  border-radius: 2px;
  background-color: currentColor;
  box-shadow: 0 6px currentColor, 0 -6px currentColor;
  transition: 0.2s;
}

.mobileButton:focus,
.mobileButton:hover,
.mobileButtonActive {
  outline: none;
  background: white;
  box-shadow: 0 0 0 3px #fea;
  color: #fb1;
  border-color: #fb1;
}

.mobileButtonActive::after {
  transform: rotate(90deg);
  width: 4px;
  height: 4px;
  box-shadow: 0 8px currentColor, 0 -8px currentColor;
}

.navMobile {
  display: block;
  position: absolute;
  top: 70px;
  right: 0px;
  padding: 0 1rem;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 0;
  pointer-events: none;
}

.navMobileActive {
  transition: 0.3s;
  transform: initial;
  pointer-events: initial;
  opacity: 1;
  z-index: 100;
}

.navMobile a,
.navMobile button {
  display: flex;
  align-items: center;
  background: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  padding: 0.5rem 0;
  color: var(--color-darker-grey);
}

.navMobile a:hover svg > *,
.navMobile button:hover svg > * {
  fill: #fb1;
}

.navMobile button {
  border-bottom: none;
}

.navMobile svg {
  margin-right: 0.5rem;
}

.navMobileActive::after {
  transform: rotate(90deg);
  width: 4px;
  height: 4px;
  box-shadow: 0 8px currentColor, 0 -8px currentColor;
}
