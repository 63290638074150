.wrapper {
  margin-bottom: 1rem;
}

.input {
  border: 1px solid #eee;
  color: #eee;
  display: block;
  width: 100%;
  font-size: var(--font-size-s);
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: var(--color-bg);
  transition: 0.2;
}

.input:hover,
.input:focus {
  outline: none;
  border-color: #fb1;
  box-shadow: 0 0 0 3px #fea;
}

.label {
  display: block;
  font-size: var(--font-size-m);
  line-height: 1;
  padding-bottom: 0.5rem;
}
