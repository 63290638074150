.feed {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
  justify-items: center;
}

.boxMessage {
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.08);
  border-radius: 0.2rem;
  display: grid;
  align-items: center;
  grid-column: 1 / 3;
  padding: 1rem;
  font-size: 1rem;
}

@media (max-width: 40rem) {
  .feed {
    grid-template-columns: repeat(2, 1fr);
  }
}
