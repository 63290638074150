.button {
  font-size: var(--font-size-s);
  font-family: var(--base-type);
  font-weight: 700;
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #fb1;
  color: #764701;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  min-width: 8rem;
  transition: 0.2;
}

.button:hover,
.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #fea, 0 0 0 #fb1;
}

.button:disabled {
  opacity: 0.5;
  cursor: wait;
}
