.footer {
  background: #fb1;
  padding: 1rem 1rem 0 1rem;
  height: 6rem;
  text-align: center;
  color: #764701;
}

.footer p {
  margin-top: 1rem;
}
