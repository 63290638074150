.wrapper {
  display: grid;
}

.img {
  display: block;
  max-width: 100%;
  grid-area: 1/1;
  opacity: 0;
  transition: 0.2s;
}

.skeleton {
  grid-area: 1/1;
  height: 100%;
  background-image: linear-gradient(90deg, #333 0px, #161b22 0px, #333 100%);
  background-color: #333;
  background-size: 200%;
  animation: skeleton 1.5s infinite linear;
}

@keyframes skeleton {
  from {
    background-position: opx;
  }
  to {
    background-position: -200%;
  }
}
