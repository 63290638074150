.graph {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.graphItem {
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.08);
  border-radius: 0.2rem;
  display: grid;
  align-items: center;
}

.total {
  grid-column: 1 / 3;
  padding: 1rem;
  font-size: 1rem;
}

@media (max-width: 40rem) {
  .graph {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
  }

  .total {
    grid-column: 1;
  }
}
